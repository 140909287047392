<template>
  <nav 
  class="navbar navbar-expand-md container-fluid justify-content-between p-0 nav-color">
    <section 
    class="row justify-content-between flex-fill m-0">
      <div 
      class="col-md-5 align-items-center p-2">
        <section 
        class="row flex-fill justify-content-start">
          <div 
          class="col-3">
            <a 
            class="nav-link pointer text-white p-0" 
            aria-current="page" 
            href="/">
              <img 
              class="logo" 
              src="../assets/VSTLogoTransparent.png" 
              alt="">
            </a>
          </div>
          <div 
          class="col-8 p-0 text-start d-flex align-items-center">
            <h2 
            class="d-none d-md-block">
            Vassar & Smith Technologies
            </h2>
          </div>
        </section>
      </div>
      <div 
      class=" d-flex flex-column col-md-3 p-0">
        <section 
        class="row justify-content-center m-0">
          <button 
          class="d-md-block d-none col-md-8 text-center btn button-style-two m-2"
          data-bs-toggle="modal" 
          data-bs-target="#contactForm">
          Contact Us
          </button>
        </section>
        <section 
        class="row">
          <button 
          class="col-12 navbar-toggler custom-toggler" 
          type="button" 
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav" 
          aria-controls="navbarNav" 
          aria-expanded="false"
          aria-label="Toggle navigation">
            <span 
            class="navbar-toggler-icon">
            </span>
          </button>
        </section>

        <section 
        class="row justify-content-start collapse navbar-collapse p-0 m-0" 
        id="navbarNav">
          <ul 
          class="col-12 navbar-nav pe-0 m-0">
            <section 
            class="row flex-fill m-0 justify-content-center align-content-end">
              <li 
              class="col-3 nav-item p-0 m-0">
                <a 
                class="nav-link pointer text-white p-0" 
                aria-current="page" 
                href="/">
                  <span 
                  class="text-dark button-border pb-0 mx-1 underline" 
                  data-bs-target="#navbarNav"
                  data-bs-toggle="collapse">
                  Home
                  </span>
                </a>
              </li>
              <li 
              class="col-3 nav-item p-0">
                <a 
                class="nav-link pointer text-white p-0" 
                aria-current="page" 
                href="/services">
                  <span 
                  class="text-dark button-border pb-0 mx-1 underline" 
                  data-bs-target="#navbarNav"
                  data-bs-toggle="collapse">
                  Services
                  </span>
                </a>
              </li>
              <li 
              class="col-3 nav-item p-0">
                <a 
                class="nav-link pointer text-white p-0" 
                aria-current="page" 
                href="/team">
                  <span 
                  class="text-dark button-border pb-0 mx-1 underline" 
                  data-bs-target="#navbarNav"
                  data-bs-toggle="collapse">
                  The Team
                </span>
                </a>
              </li>
              <li 
              class="col-12 nav-item p-0 d-md-none">
                <a 
                class="nav-link pointer text-white p-0" 
                data-bs-target="#navbarNav" 
                data-bs-toggle="collapse">
                  <span 
                  class="text-dark button-border pb-0 mx-1 underline"
                  data-bs-target="#contactForm"
                  data-bs-toggle="modal"> 
                  Contact Us
                </span>
                </a>
              </li>
            </section>
          </ul>
        </section>
      </div>
    </section>
  </nav>
</template>


<script>

export default {
  setup() {



    return {
      hoverEffect: false,
      clickEffect: false
    }
  }
};
</script>


<style scoped>
.logo {
  height: 13vh;
}

.navbar-mine {
  height: 10%;
  border-bottom: 4px solid black;
}

.button-style-two {
  border: 2px solid #08494B;
  z-index: 3;
  transition: all 0.3s ease; /* Smooth transition for effects */
}

/* Hover effects */
.button-style-two:hover {
  background-color: #08494B; /* Darken background on hover */
  color: white;              /* Change text color to white */
  transform: translateY(-2px); /* Subtle lift on hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a shadow */
}

/* Active effect (when clicked) */
.button-style-two:active {
  transform: translateY(0);   /* Remove lift on click */
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1); /* Reduce shadow */
}

/* Underline effect for navigation links */
.nav-link .underline {
  border-bottom: 2px solid transparent; /* Initial state: no underline */
  transition: border-color 0.3s ease;
}

.nav-link:hover .underline {
  border-color: #08494B; /* Add underline on hover */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) { /* Adjust breakpoint as needed */

/* Stack Logo and Text */
.navbar .row:first-child {
  flex-direction: column; /* Stack elements vertically */
  align-items: center;
}

/* Stack Nav Links */
.navbar-nav {
  flex-direction: column;
}

/* Center Contact Button */
.btn {
  margin: 1rem auto; /* Center button */
}

/* Adjust logo size */
.logo {
  height: 10vh; /* Or use max-width for a more flexible approach */
}
}
</style>